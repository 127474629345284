import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import { COMPANIES_QUERY } from '../../api/public/cabinet/companies';

import styles from './CabinetProfileCard.module.scss';
import { CompaniesQuery } from '../../__generated__/graphql';

export const COMPANIES_STORAGE_KEY = 'companies';

export const CabinetProfileInfoCard = () => {
  const [companies, setCompanies] = useState<CompaniesQuery['companies']>([]);

  useEffect(() => {
    try {
      const companies = sessionStorage.getItem(COMPANIES_STORAGE_KEY);
      if (companies) {
        setCompanies(JSON.parse(companies));
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const { loading, error } = useQuery(COMPANIES_QUERY, {
    skip: companies.length > 0,
    onCompleted: (data) => {
      try {
        sessionStorage.setItem(COMPANIES_STORAGE_KEY, JSON.stringify(data.companies));
        setCompanies(data.companies);
      }
      catch (err) {
        console.error(err);
      }
    },
    fetchPolicy: 'no-cache',
  });

  return companies.length === 0 ? (
    <div className={styles.companyContainer}>
      <div className={styles.profileCardContainer}>
        <Image
          className={styles.profileIcon}
          src="/svg/profile_info.svg"
          width={28}
          height={44}
          alt="company"
        />
        {loading ? null : error ? (
          <ErrorAlert error={'Не удалось получить данные о компании'} />
        ) : (
          <Link href={'/cabinet/companies/new'} className={styles.companyNoInfo}>
            Заполните профиль<br />компании
          </Link>
        )}
      </div>
    </div>
  ) : null;
};

export default CabinetProfileInfoCard;
